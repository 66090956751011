






















import AccountsTable from '@/components/tables/AccountsTable.vue'
import CustomersTable from '@/components/tables/CustomersTable.vue'
import MissingComponent from '@/components/cards/MissingComponent.vue'
import FinancialSnapshots from '@/components/cards/FinancialSnapshots.vue'
import {Store} from '@/store/store'
import Component from 'vue-class-component'

import {
  CreateCustomerAccount
} from '@/router';

@Component({
  components: {
    AccountsTable,
    CustomersTable,
    MissingComponent,
    FinancialSnapshots
  },
  metaInfo: {
    title: 'Dashboard'
  }
})
export default class ResellerDashboard extends Store {
  componentTitle = 'Dashboard';
  CreateCustomerAccount = CreateCustomerAccount
}
